.header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--color-D20);
}

.container {
  overflow-x: hidden;
}
