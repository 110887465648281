.frame {
  border: none;
}

.container {
  position: relative;
  height: 100%;
  /* stylelint-disable */
  max-height: -webkit-fill-available !important;
  /* stylelint-enable */
}

.invisible {
  opacity: 0;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
